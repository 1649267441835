<template>
    <div class="section">
        <page-title :title="$t('sst.pages.metalRollout.title')" class="my-3"></page-title>
        <b-card>
            <b-row class="my-3">
                <b-col lg="4">
                    <multiselect
                        v-model="selectedSite"
                        id="ajax"
                        label="name"
                        track-by="id"
                        :placeholder="$t('sst.pages.metalRollout.multiselect.searchPlaceholder')"
                        open-direction="bottom"
                        :options="sites"
                        :multiple="false"
                        :searchable="true"
                        :loading="isHeaderLoading"
                        :internal-search="false"
                        :close-on-select="true"
                        :show-no-results="true"
                        :hide-selected="false"
                        @search-change="search"
                        :show-labels="false"
                        @input="change"
                    >
                        <template slot="noResult">
                            <span>
                                {{ $t('sst.pages.metalRollout.multiselect.noResults') }}
                            </span>
                        </template>
                        <template slot="noOptions">
                            <span>
                                {{ $t('sst.pages.metalRollout.multiselect.noResults') }}
                            </span>
                        </template>
                    </multiselect>
                </b-col>
            </b-row>
            <b-row v-if="displayTable">
                <b-col class="lg-12">
                    <spinner v-if="refreshData"></spinner>
                    <div v-if="!refreshData">
                        <b-button pill variant="primary" v-b-modal="'modal-metal-rollout'">
                            {{ $t('sst.pages.metalRollout.buttons.add') }}
                        </b-button>
                        <custom-table
                            v-if="!refreshData"
                            :tableConfig="tableConfig"
                            :fields="fields"
                            :items="items"
                            :page="page"
                        ></custom-table>
                    </div>
                </b-col>
            </b-row>
            <sst-metal-rollout-modal
                v-if="selectedSite"
                :id="'modal-metal-rollout'"
                :site="selectedSite"
                :title="$t('sst.pages.metalRollout.modal.create.title')"
            >
            </sst-metal-rollout-modal>
        </b-card>
    </div>
</template>

<script>
import PageTitle from '@/layouts/components/PageTitle.vue';
import { SiteRepository } from '@/repositories';
import Spinner from '@/components/shared/element/Spinner';
import CustomTable from '@/components/shared/table/CustomTable';
import { TABLE } from '@/constants';
import SstMetalRolloutModal from '@/components/sst/metal-rollout/SstMetalRolloutModal';

export default {
    name: 'SstSiteList',
    components: {
        PageTitle,
        Spinner,
        CustomTable,
        SstMetalRolloutModal
    },
    data() {
        return {
            selectedSite: [],
            sites: [],
            items: [],
            isHeaderLoading: false,
            fields: [
                {
                    key: 'full_address',
                    label: this.$t('sst.pages.metalRollout.table.columns.fullAddress'),
                    sortable: true,
                    class: 'text-left'
                },
                {
                    key: 'size',
                    label: this.$t('sst.pages.metalRollout.table.columns.size'),
                    sortable: true,
                    class: 'text-left'
                },
                {
                    key: 'axe_access_display',
                    label: this.$t('sst.pages.metalRollout.table.columns.axeAccess'),
                    sortable: true,
                    class: 'text-left'
                },
                {
                    key: 'engine_access_display',
                    label: this.$t('sst.pages.metalRollout.table.columns.engineAccess'),
                    sortable: true,
                    class: 'text-left'
                },
                {
                    key: 'axe_type_display',
                    label: this.$t('sst.pages.metalRollout.table.columns.axeType'),
                    sortable: true,
                    class: 'text-left'
                },
                {
                    key: 'material',
                    label: this.$t('sst.pages.metalRollout.table.columns.material'),
                    sortable: true,
                    class: 'text-left'
                },
                {
                    key: 'avoid_fall_display',
                    label: this.$t('sst.pages.metalRollout.table.columns.avoidFall'),
                    sortable: true,
                    class: 'text-left'
                },
                {
                    key: 'unclutch_display',
                    label: this.$t('sst.pages.metalRollout.table.columns.unclutch'),
                    sortable: true,
                    class: 'text-left'
                },
                {
                    key: 'memo',
                    label: this.$t('sst.pages.metalRollout.table.columns.memo'),
                    sortable: true,
                    class: 'text-left'
                },
                {
                    key: 'actions',
                    label: this.$t('sst.pages.metalRollout.table.columns.actions'),
                    sortable: false,
                    class: 'text-left'
                }
            ],
            tableConfig: {
                sortBy: 'sst_bill_record_date',
                striped: true,
                bordered: true,
                headVariant: 'dark',
                sortDesc: true,
                currentPage: 1,
                displayCount: false,
                displayTotalRows: false
            },
            page: TABLE.PAGE.SST.METAL_ROLLOUT.ID,
            refreshData: false,
            displayTable: false
        };
    },
    created() {
        // eventBus.$on('reloadSstMetalRolloutsTable', () => {
        //     this.change();
        // });
    },
    methods: {
        search(query) {
            if (query.length >= 3) {
                this.isLoading = true;
                SiteRepository.searchSitesWithMetalRollouts(query).then(response => {
                    this.sites = response.data.data;
                    this.isHeaderLoading = false;
                });
            }
        },
        change() {
            if (this.selectedSite) {
                this.displayTable = true;
                this.refreshData = true;
                SiteRepository.metalRolloutsBySite(this.selectedSite.id).then(response => {
                    this.items = response.data.data;
                    this.refreshData = false;
                });
            } else {
                this.displayTable = false;
            }
        }
    }
};
</script>
